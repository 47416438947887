<template>
  <svg width="80" height="80" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_600_10515)">
      <path d="M42.5458 52.5006L42.1555 52.543L41.4038 52.2182L39.24 51.2836L35.198 49.8383L30.8874 48.5632L26.7781 47.6361L22.8533 47.034L19.0954 46.7391L17.6879 46.4964L16.8702 46.0121L16.4483 45.3162L16.3813 44.3159L16.7977 42.8325L17.7267 41.1429L19.0074 39.6399L20.6852 38.3061L22.8251 37.1411L24.9244 36.4066L26.9349 36.1099L28.8949 36.2197L30.8447 36.736L32.8207 37.6932L34.5591 38.9372L36.2496 40.5788L37.8991 42.6784L39.506 45.3076L41.0595 48.5503L42.2561 51.7317L42.5458 52.5006Z" :fill="secondaryColor"/>
      <path d="M84.9283 25.6631L80.4907 24.8406L79.7985 28.5756L84.2361 29.398L84.9283 25.6631Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M74.9081 79.7274L70.4706 78.9049L69.7715 82.6768L74.2091 83.4993L74.9081 79.7274Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M77.3974 29.3545L76.6083 32.6833L75.5444 35.7202L74.2133 38.4961L72.6165 41.0336L70.7482 43.3543L68.5963 45.4713L66.1419 47.3583L63.7083 48.7461L61.2753 49.6865L58.8188 50.2124L57.4868 50.2791L56.6946 50.1935L57.4569 50.1464L59.2182 49.7289L60.9366 48.9965L62.6339 47.9235L64.4827 46.2705L65.9231 44.3754L66.9858 42.2066L67.674 39.7159L68.8323 33.4663L45.59 28.8526L44.3768 35.398L44.2056 37.1631L44.1268 37.9699L44.3417 40.3755L45.0073 42.6609L46.1409 44.8667L47.341 46.4767L48.6827 47.7763L50.1774 48.7972L51.1879 49.258L50.9186 49.2712L48.8823 48.3707L46.7774 46.9995L44.8431 45.2497L43.0684 43.0819L41.4532 40.4407L40.203 37.6931L39.2904 34.8569L38.7091 31.9158L38.4612 28.8472L38.5562 25.6308L39.0125 22.2403L39.303 21.0702L77.5455 28.158L77.3974 29.3545Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M80.4908 24.8406L37.7423 16.9176L37.0501 20.6526L79.7986 28.5756L80.4908 24.8406Z" fill="white"/>
      <path d="M52.8634 49.8296L52.8629 49.8324L51.182 49.3879L50.9186 49.2712L51.1879 49.258L51.8431 49.5563L52.8634 49.8296Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M52.8629 49.8324L52.0334 52.0788L50.3088 56.003L48.4657 59.5648L46.0484 63.4496L43.7046 66.4787L41.4288 68.7793L39.2103 70.4614L37.0302 71.616L34.8591 72.3047L29.975 71.3995L30.1231 70.203L30.9124 66.8733L31.9769 63.8384L33.3079 61.0682L34.9033 58.5381L36.7686 56.2284L38.6416 54.3933L38.8187 54.4491L40.5827 55.0438L41.4044 55.1817L41.6291 54.97L41.5401 54.3769L40.9048 53.3057L40.5152 52.895L41.3718 52.2362L41.4038 52.2182L42.1554 52.543L42.5458 52.5006L42.2561 51.7317L43.8054 50.8484L46.2383 49.9079L48.6949 49.3821L50.9186 49.2712L51.182 49.3879L52.8629 49.8324Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M68.5081 77.3172L68.2176 78.4873L62.0022 77.3354L60.7961 76.8212L59.5865 75.9333L58.3535 74.5889L57.09 72.6765L55.9194 70.1951L55.0179 67.2482L54.4193 63.755L54.1734 59.6282L54.3415 54.7691L54.8723 50.1818L55.9587 50.3L57.4118 50.6841L58.6314 51.2237L60.7363 52.595L62.6706 54.3448L64.4453 56.5126L66.0605 59.1538L67.312 61.8892L68.2257 64.7141L68.8084 67.6478L69.0583 70.7111L68.9645 73.9258L68.5081 77.3172Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M73.4021 74.7483L72.9457 78.1397L72.6552 79.3098L68.2176 78.4874L68.5081 77.3172L68.9645 73.9258L69.0583 70.7111L68.8085 67.6478L68.2257 64.7141L67.312 61.8893L66.0605 59.1538L64.4453 56.5126L62.6707 54.3449L60.7363 52.595L58.6314 51.2238L57.4118 50.6841L56.596 50.3235L57.4868 50.2791L58.8188 50.2124L61.2754 49.6866L63.7083 48.7461L66.142 47.3583L68.5964 45.4714L70.7483 43.3543L72.6166 41.0336L74.2133 38.4962L75.5444 35.7202L76.6084 32.6833L77.3974 29.3546L77.5456 28.158L81.9831 28.9805L81.835 30.177L81.0459 33.5058L79.982 36.5426L78.6509 39.3186L77.0541 41.856L75.1858 44.1767L73.0339 46.2938L70.5795 48.1808L68.1459 49.5686L65.7129 50.509L63.2564 51.0348L61.0336 51.1459L63.0689 52.0462L65.1739 53.4175L67.1082 55.1673L68.8829 57.3351L70.498 59.9763L71.7496 62.7117L72.6633 65.5366L73.246 68.4703L73.4959 71.5336L73.4021 74.7483Z" :fill="primaryColor" fill-opacity="0.4"/>
      <path d="M57.4868 50.2791L56.596 50.3235L56.3074 50.3379L55.9587 50.3L54.8723 50.1819L54.873 50.1782L55.6299 50.2582L55.7682 50.2494L56.3485 50.2145L56.6947 50.1935L57.4868 50.2791Z" :fill="primaryColor" fill-opacity="0.4"/>
      <path d="M57.4118 50.6841L55.9587 50.3L54.8723 50.1819L54.873 50.1782L55.6299 50.2582L55.7682 50.2494L56.3485 50.2145L56.596 50.3235L57.4118 50.6841Z" :fill="primaryColor" fill-opacity="0.4"/>
      <path d="M70.4706 78.9049L27.722 70.9819L27.023 74.7539L69.7715 82.6768L70.4706 78.9049Z" fill="white"/>
      <path d="M62.0021 77.3354L34.8591 72.3047L37.0302 71.616L39.2102 70.4614L41.4288 68.7794L43.7045 66.4787L46.0484 63.4496L48.4657 59.5648L50.3088 56.003L52.0334 52.0788L52.6902 50.2995L52.8629 49.8324L52.8634 49.8296L51.843 49.5563L51.1879 49.258L50.1774 48.7972L48.6827 47.7762L47.3409 46.4767L46.1409 44.8667L45.0073 42.6609L44.3417 40.3755L44.1268 37.9699L44.2055 37.1631L44.3768 35.398L45.5899 28.8526L68.8323 33.4663L67.674 39.7159L66.9858 42.2066L65.923 44.3754L64.4826 46.2705L62.6338 47.9235L60.9365 48.9964L59.2182 49.7289L57.4568 50.1464L56.6946 50.1935L56.3484 50.2145L55.7681 50.2494L55.6298 50.2582L54.8729 50.1781L54.8722 50.1818L54.3414 54.7691L54.1733 59.6282L54.4192 63.755L55.0178 67.2482L55.9194 70.1951L57.0899 72.6765L58.3534 74.5889L59.5864 75.9333L60.796 76.8211L62.0021 77.3354Z" :fill="primaryColor" fill-opacity="0.8"/>
      <path d="M41.6292 54.97L41.4044 55.1817L40.5828 55.0438L38.8187 54.4491L38.6416 54.3933L38.0646 54.2099L37.482 53.8189L37.1005 53.3389L37.048 52.8587L37.2691 52.4292L37.7973 52.1178L38.4813 51.9615L39.2711 52.0085L40.0016 52.3542L40.5153 52.895L40.9049 53.3058L41.5402 54.3769L41.6292 54.97Z" :fill="secondaryColor"/>
    </g>
    <defs>
      <clipPath id="clip0_600_10515">
        <rect width="64" height="58.8212" fill="white" transform="translate(22 14) rotate(10.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    primaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_primary')
    },
    secondaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_secondary')
    }
  }
}
</script>
=======
<template>
  <svg width="80" height="80" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_600_10515)">
      <path d="M42.5458 52.5006L42.1555 52.543L41.4038 52.2182L39.24 51.2836L35.198 49.8383L30.8874 48.5632L26.7781 47.6361L22.8533 47.034L19.0954 46.7391L17.6879 46.4964L16.8702 46.0121L16.4483 45.3162L16.3813 44.3159L16.7977 42.8325L17.7267 41.1429L19.0074 39.6399L20.6852 38.3061L22.8251 37.1411L24.9244 36.4066L26.9349 36.1099L28.8949 36.2197L30.8447 36.736L32.8207 37.6932L34.5591 38.9372L36.2496 40.5788L37.8991 42.6784L39.506 45.3076L41.0595 48.5503L42.2561 51.7317L42.5458 52.5006Z" :fill="secondaryColor"/>
      <path d="M84.9283 25.6631L80.4907 24.8406L79.7985 28.5756L84.2361 29.398L84.9283 25.6631Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M74.9081 79.7274L70.4706 78.9049L69.7715 82.6768L74.2091 83.4993L74.9081 79.7274Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M77.3974 29.3545L76.6083 32.6833L75.5444 35.7202L74.2133 38.4961L72.6165 41.0336L70.7482 43.3543L68.5963 45.4713L66.1419 47.3583L63.7083 48.7461L61.2753 49.6865L58.8188 50.2124L57.4868 50.2791L56.6946 50.1935L57.4569 50.1464L59.2182 49.7289L60.9366 48.9965L62.6339 47.9235L64.4827 46.2705L65.9231 44.3754L66.9858 42.2066L67.674 39.7159L68.8323 33.4663L45.59 28.8526L44.3768 35.398L44.2056 37.1631L44.1268 37.9699L44.3417 40.3755L45.0073 42.6609L46.1409 44.8667L47.341 46.4767L48.6827 47.7763L50.1774 48.7972L51.1879 49.258L50.9186 49.2712L48.8823 48.3707L46.7774 46.9995L44.8431 45.2497L43.0684 43.0819L41.4532 40.4407L40.203 37.6931L39.2904 34.8569L38.7091 31.9158L38.4612 28.8472L38.5562 25.6308L39.0125 22.2403L39.303 21.0702L77.5455 28.158L77.3974 29.3545Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M80.4908 24.8406L37.7423 16.9176L37.0501 20.6526L79.7986 28.5756L80.4908 24.8406Z" fill="white"/>
      <path d="M52.8634 49.8296L52.8629 49.8324L51.182 49.3879L50.9186 49.2712L51.1879 49.258L51.8431 49.5563L52.8634 49.8296Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M52.8629 49.8324L52.0334 52.0788L50.3088 56.003L48.4657 59.5648L46.0484 63.4496L43.7046 66.4787L41.4288 68.7793L39.2103 70.4614L37.0302 71.616L34.8591 72.3047L29.975 71.3995L30.1231 70.203L30.9124 66.8733L31.9769 63.8384L33.3079 61.0682L34.9033 58.5381L36.7686 56.2284L38.6416 54.3933L38.8187 54.4491L40.5827 55.0438L41.4044 55.1817L41.6291 54.97L41.5401 54.3769L40.9048 53.3057L40.5152 52.895L41.3718 52.2362L41.4038 52.2182L42.1554 52.543L42.5458 52.5006L42.2561 51.7317L43.8054 50.8484L46.2383 49.9079L48.6949 49.3821L50.9186 49.2712L51.182 49.3879L52.8629 49.8324Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M68.5081 77.3172L68.2176 78.4873L62.0022 77.3354L60.7961 76.8212L59.5865 75.9333L58.3535 74.5889L57.09 72.6765L55.9194 70.1951L55.0179 67.2482L54.4193 63.755L54.1734 59.6282L54.3415 54.7691L54.8723 50.1818L55.9587 50.3L57.4118 50.6841L58.6314 51.2237L60.7363 52.595L62.6706 54.3448L64.4453 56.5126L66.0605 59.1538L67.312 61.8892L68.2257 64.7141L68.8084 67.6478L69.0583 70.7111L68.9645 73.9258L68.5081 77.3172Z" :fill="primaryColor" fill-opacity="0.2"/>
      <path d="M73.4021 74.7483L72.9457 78.1397L72.6552 79.3098L68.2176 78.4874L68.5081 77.3172L68.9645 73.9258L69.0583 70.7111L68.8085 67.6478L68.2257 64.7141L67.312 61.8893L66.0605 59.1538L64.4453 56.5126L62.6707 54.3449L60.7363 52.595L58.6314 51.2238L57.4118 50.6841L56.596 50.3235L57.4868 50.2791L58.8188 50.2124L61.2754 49.6866L63.7083 48.7461L66.142 47.3583L68.5964 45.4714L70.7483 43.3543L72.6166 41.0336L74.2133 38.4962L75.5444 35.7202L76.6084 32.6833L77.3974 29.3546L77.5456 28.158L81.9831 28.9805L81.835 30.177L81.0459 33.5058L79.982 36.5426L78.6509 39.3186L77.0541 41.856L75.1858 44.1767L73.0339 46.2938L70.5795 48.1808L68.1459 49.5686L65.7129 50.509L63.2564 51.0348L61.0336 51.1459L63.0689 52.0462L65.1739 53.4175L67.1082 55.1673L68.8829 57.3351L70.498 59.9763L71.7496 62.7117L72.6633 65.5366L73.246 68.4703L73.4959 71.5336L73.4021 74.7483Z" :fill="primaryColor" fill-opacity="0.4"/>
      <path d="M57.4868 50.2791L56.596 50.3235L56.3074 50.3379L55.9587 50.3L54.8723 50.1819L54.873 50.1782L55.6299 50.2582L55.7682 50.2494L56.3485 50.2145L56.6947 50.1935L57.4868 50.2791Z" :fill="primaryColor" fill-opacity="0.4"/>
      <path d="M57.4118 50.6841L55.9587 50.3L54.8723 50.1819L54.873 50.1782L55.6299 50.2582L55.7682 50.2494L56.3485 50.2145L56.596 50.3235L57.4118 50.6841Z" :fill="primaryColor" fill-opacity="0.4"/>
      <path d="M70.4706 78.9049L27.722 70.9819L27.023 74.7539L69.7715 82.6768L70.4706 78.9049Z" fill="white"/>
      <path d="M62.0021 77.3354L34.8591 72.3047L37.0302 71.616L39.2102 70.4614L41.4288 68.7794L43.7045 66.4787L46.0484 63.4496L48.4657 59.5648L50.3088 56.003L52.0334 52.0788L52.6902 50.2995L52.8629 49.8324L52.8634 49.8296L51.843 49.5563L51.1879 49.258L50.1774 48.7972L48.6827 47.7762L47.3409 46.4767L46.1409 44.8667L45.0073 42.6609L44.3417 40.3755L44.1268 37.9699L44.2055 37.1631L44.3768 35.398L45.5899 28.8526L68.8323 33.4663L67.674 39.7159L66.9858 42.2066L65.923 44.3754L64.4826 46.2705L62.6338 47.9235L60.9365 48.9964L59.2182 49.7289L57.4568 50.1464L56.6946 50.1935L56.3484 50.2145L55.7681 50.2494L55.6298 50.2582L54.8729 50.1781L54.8722 50.1818L54.3414 54.7691L54.1733 59.6282L54.4192 63.755L55.0178 67.2482L55.9194 70.1951L57.0899 72.6765L58.3534 74.5889L59.5864 75.9333L60.796 76.8211L62.0021 77.3354Z" :fill="primaryColor" fill-opacity="0.8"/>
      <path d="M41.6292 54.97L41.4044 55.1817L40.5828 55.0438L38.8187 54.4491L38.6416 54.3933L38.0646 54.2099L37.482 53.8189L37.1005 53.3389L37.048 52.8587L37.2691 52.4292L37.7973 52.1178L38.4813 51.9615L39.2711 52.0085L40.0016 52.3542L40.5153 52.895L40.9049 53.3058L41.5402 54.3769L41.6292 54.97Z" :fill="secondaryColor"/>
    </g>
    <defs>
      <clipPath id="clip0_600_10515">
        <rect width="64" height="58.8212" fill="white" transform="translate(22 14) rotate(10.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    primaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_primary')
    },
    secondaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_secondary')
    }
  }
}
</script>
