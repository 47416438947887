<template>
  <div class="d-flex navigation-layout" :style="`background-image: url('${ layouts.data.portfolio.grayscaleIconUrl }')`">
    <div class="nav">
      <div class="d-flex justify-center align-center logo">
        <img :src="layouts.data.portfolio.iconUrl"/>
      </div>

      <v-icon
        dark
        v-if="$route.path.includes('loan-details') || $route.path.includes('change-password')"
        class="icon"
        size="45"
        @click="onClickHome"
      >fa-home</v-icon>

      <div v-if="$route.path.includes('home')">
        <v-card
          class="icon"
          color="transparent"
          flat
          v-for="item in dataSources.original.navigationList"
          :key="item.path"
          icon
          @click="clickIcon(item)"
        >
          <v-card-text class="py-0">
            <v-icon
              class="text-white" size="45"
            >{{ item.icon }}
            </v-icon>
          </v-card-text>
          <div class="icon-text">{{item.name}}</div>
        </v-card>
      </div>
    </div>
    <v-spacer></v-spacer>
    <v-card class="center-main">
      <div class="top">
        <div class="ml-4 f-s-20 f-w-700">{{ layouts.data.title }}</div>

        <v-spacer></v-spacer>

        <SameDayCutDown/>

        <v-spacer></v-spacer>

        <!--<v-tooltip bottom color="var(&#45;&#45;theme_primary)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mr-3">
              <v-icon size="24">fa fa-phone-alt</v-icon>
            </v-btn>
          </template>
          <span
          >Questions? Call us at
            <span class="phone-number">{{
                layouts.data.portfolio.hotlinePhone
              }}</span></span
          >
        </v-tooltip>-->

        <v-menu offset-y v-if="$route.path.includes('home') || $route.path.includes('repayment')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="mr-3"
            >
              <v-icon size="24">mdi-text</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in actionMenu"
              link
              :key="index"
              @click="listActions(item.title)"
            >
              <v-list-item-title link>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="router-main" :class="$route.path.includes('collection') ? 'px-16 py-12' : 'application-padding'">
        <router-view ref="childComponent"></router-view>
      </div>
    </v-card>
  </div>
</template>

<script>
import { Tool, Cache } from '@/assets/js/util'
import SameDayCutDown from '@/components/common/SameDayCutDown'

export default {
  components: {
    SameDayCutDown
  },
  data () {
    return {
      assist: {
        tool: new Tool(),
        cache: new Cache()
      },
      actionMenu: [
        { title: 'Change Password' },
        { title: 'Sign Out' }
      ],
      dataSources: {
        original: {
          navigationList: [
            {
              icon: 'fa-hand-holding-usd',
              path: '/disbursement/loan-application',
              name: 'Disbursement'
            },
            {
              icon: 'fa-envelope-open-dollar',
              path: '/repayment/loan-details',
              name: 'Repayment'
            }
          ]
        }
      },
      layouts: {
        data: {
          title: '',
          portfolio: {
            iconUrl: '',
            hotlinePhone: '',
            grayscaleIconUrl: ''
          }
        }
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.portfolio.iconUrl = val.iconUrl
          this.layouts.data.portfolio.grayscaleIconUrl = val.grayscaleIconUrl
          this.layouts.data.portfolio.hotlinePhone = val.hotlinePhone
        }
      },
      immediate: true,
      deep: true
    },
    '$route' (val) {
      this.layouts.data.title = val.meta.title
    }
  },
  mounted () {
    this.layouts.data.title = this.$route.meta.title
    const portfolioJson = this.store.getters.getPortfolioJson
    if (this.assist.tool.isNotEmpty(portfolioJson)) {
      this.layouts.data.portfolio = portfolioJson
    }
  },
  methods: {
    clickIcon ({ name }) {
      this.$refs.childComponent.cardDisplayControl = name
    },
    listActions (title) {
      if (title === 'Change Password') {
        this.$router.push('/change-password')
      } else {
        this.assist.cache.clear()
        this.$router.push('/sign-in')
      }
    },
    onClickHome () {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang='sass' scoped>
.navigation-layout
  height: 100%
  width: 100%
  background-position: right
  background-color: #F5F5F7

.nav
  display: flex
  flex-direction: column
  width: 368px
  height: 100vh
  padding-top: 180px
  padding-left: 60px
  background: var(--theme_primary)

  & .logo
    width: 96px
    height: 96px
    margin-bottom: 104px
    background: #ffffff
    border-radius: 16px
    box-shadow: 0px 48px 96px 0px var(--theme_primary_opacity_low)

    & img
      width: 64px
      height: 64px

  & .icon
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 80px
    height: 80px
    margin-left: 5px
    margin-bottom: 35px
    &-text
      color: white
      white-space: nowrap
      text-align: center
      font-weight: 700
.center-main
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 78%
  height: 88%
  background: linear-gradient(90deg, #ffffff 70%, rgba(255, 255, 255, .6), transparent)
  border-radius: 24px
  overflow: hidden

  & .top
    display: flex
    align-items: center
    height: 72px
    box-shadow: 0px 1px 2px 0px #e9ebf2

    & .userIcon
      width: 40px
      height: 40px
      border-radius: 100%
      margin-right: 64px
      box-shadow: 0px 12px 24px 0px rgba(131, 147, 198, 0.30)

  & .router-main
    height: calc(100% - 72px)

  & .application-padding
    padding: 0 128px
</style>
