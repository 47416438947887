<template>
  <v-card
    class="py-2 pl-4 same-day"
    flat
    color="var(--theme_secondary_opacity_high)"
    v-if="layouts.sameDay.countDownDisplay"
    @click="layouts.sameDay.showIntroduction = true"
  >
    <div>
      <div class="same-day-title">
        Time Remaining for Same Day Funding:
      </div>
      <van-count-down
        class="count-down f-s-18 f-w-900"
        :time="layouts.sameDay.time"
        @finish="countDownFinished"
      />
    </div>
    <sand-glass
      class="mt-n6"
      primary-color="var(--theme_secondary)"
      secondary-color="var(--theme_primary)"
    ></sand-glass>

    <!-- Introduction Same day -->
    <v-dialog
      v-model="layouts.sameDay.showIntroduction"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 d-flex justify-center">
          Same Day Funding
        </v-card-title>

        <v-card-text>
          <div>We will fund your account with same day of loan approval. Unavoidable delay that occur due to bank
            holidays, processing schedule of your bank, processing errors,
            "acts of God." and/or "acts of terror" may extend the time for the deposit.
          </div>
        </v-card-text>

        <v-card-actions>
          <web-btn
            width="30%"
            :height="40"
            @click="layouts.sameDay.showIntroduction = false"
          >
            OK
          </web-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    change disbursement date dialog-->
    <v-dialog
      v-model="layouts.sameDay.changeDateSheet"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex m-b-5">
            <v-icon color="red">error</v-icon>
            <div class="m-l-5">Failure</div>
          </div>
        </v-card-title>
        <v-card-text>
          You have passed the cut off time for Same Day Funding. But don't
          worry, you can change the disbursement date of your loan and request
          the fund again.
        </v-card-text>
        <v-card-actions>
          <web-btn
            width="60%"
            :height="40"
            @click="onClickChange"
          >
            Change Disbursement Date
          </web-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import SandGlass from '@/components/svg/SandGlass'
import { CountDown } from 'vant'
import { InfrastructureApi } from '@/api'
import { Tool } from '@/assets/js/util'
import moment from 'moment'
import WebBtn from '@/components/base/WebBtn'

const tool = new Tool()
export default {
  name: 'SameDayCutDown',
  components: {
    SandGlass,
    [CountDown.name]: CountDown,
    WebBtn
  },
  data: () => ({
    layouts: {
      sameDay: {
        countDownDisplay: false,
        showIntroduction: false,
        changeDateSheet: false,
        time: null,
        noCutDown: null
      }
    }
  }),
  watch: {
    '$store.getters.getLoan.effectiveDate': {
      handler () {
        this.getCutDownTime()
      },
      deep: true
    }
  },
  mounted () {
    if (!/coupon|loan-agreement/.test(this.$route.path)) {
      this.getCutDownTime()
    }
  },
  methods: {
    getCutDownTime () {
      InfrastructureApi.getCutDownTime(this.$store.getters.getPortfolioId, result => {
        // result = '00:00:55'
        if (tool.isNotEmpty(result)) {
          // 判断是否选择日期为当天
          const today = moment(new Date()).format('MM/DD/yyyy')
          const isToday = moment(today).isSame(this.$store.getters.getLoan.effectiveDate)

          if (!isToday) {
            this.layouts.sameDay.countDownDisplay = false
            return
          }

          // result = '00:00:08'
          // 页面刚渲染时，time为空，会立即触发countDownFinished方法，所以加一层限制
          this.layouts.sameDay.noCutDown = false
          this.layouts.sameDay.countDownDisplay = result.substring(0, 2) === '00'
          const dateString = '1970-01-01' // 设置一个日期
          const dateTimeString = dateString + ' ' + result // 将日期和时间字符串组合在一起
          this.layouts.sameDay.time = moment.utc(dateTimeString, 'YYYY-MM-DD HH:mm:ss').valueOf() // 这里拿到的是1970 年 1 月 1 日 00:00:00 到 1970 年 1 月 1 日 + result的时间戳
        } else {
          this.layouts.sameDay.noCutDown = true
          this.layouts.sameDay.countDownDisplay = false
        }
      })
    },
    countDownFinished () {
      if (!this.layouts.sameDay.noCutDown) {
        this.layouts.sameDay.changeDateSheet = true
      } else {
        this.layouts.sameDay.countDownDisplay = false
      }
    },
    onClickChange () {
      this.layouts.sameDay.changeDateSheet = false
      this.layouts.sameDay.countDownDisplay = false
      this.layouts.sameDay.showIntroduction = false
      this.$store.commit('setEffectiveDateChange', `${Math.random()}`)
      this.$router.push('/disbursement/loan-application')
    }
  }
}
</script>

<style lang="sass" scoped>
.same-day
  height: 90%
  display: flex
  justify-content: space-between

  &-title
    color: #696868
    line-height: 18px

  & .count-down
    color: #101010

.introduction-countdown
  color: var(--theme_secondary)
  text-align: center !important
  font-size: 52px
  font-weight: bold

</style>
